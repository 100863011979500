import React from "react"
import styled from "styled-components"

const HeroImageContainer = styled.div`
  background-image: url(${props => props.bgImage && props.bgImage});
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`

const HeroText = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  width: 80%;

  @media (min-width: 768px) {
    width: 60%;

    h1 {
      font-size: 54px;
      font-weight: normal;
    }

    p {
      font-size: 22px;
    }
  }
`

export default function HeroImage({ bgImage, height, children }) {
  return (
    <HeroImageContainer bgImage={bgImage}>
      {children && (
        <HeroText data-sal="fade" data-sal-easing="ease">
          {children}
        </HeroText>
      )}
    </HeroImageContainer>
  )
}
